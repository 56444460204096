import React, { FC, useEffect, useState } from "react";
import { Aligment, Section, Text } from "venice-ui";
import { useStoreActions, useStoreState } from "../../store";
import { addDays, format } from "date-fns";
import { pl } from "date-fns/locale";
import { dictionary } from "../../untils/dictionary";
import { t } from "../../helpers/userHelper";
import { apiCall } from "../../untils/apiCall";
import { ProgressBar } from "../UI/ProgressBar/ProgressBar";
import { encryptNip, getLimits } from "../../helpers/organizationHelper";
import { getPercentageValue } from "../../helpers/mathHelpers";
import { LoadingContent } from "../layouts/Loading";
import { EditBillingModal } from "../Modals/EditBillingModal";
import { SettingRow } from "../Settings/SettingsRow";
import { EditOrgNameModal } from "../Modals/EditOrgNameModal";
import { EditOrgMailModal } from "../Modals/EditOrgMailModal";

export const OrganizationDetails: FC = () => {
  const { id, name, creationTime, members, usedStorage, planKey } =
    useStoreState((state) => state.organization);
  const { orgPaymentID, orgSesionID } = useStoreState(
    (state) => state.user.userDetails
  );

  const { updateOrgName } = useStoreActions((actions) => actions.organization);

  const [bilingModal, toogleBilingModal] = useState(false);
  const [nameModal, toogleNameModal] = useState(false);
  const [emailModal, toogleEmailModal] = useState(false);

  const hideBillingModal = () => {
    toogleBilingModal(false);
  };
  const showBillingModal = () => {
    toogleBilingModal(true);
  };
  const hideNameModal = () => {
    toogleNameModal(false);
  };
  const showNameModal = () => {
    toogleNameModal(true);
  };
  const hideEmailModal = () => {
    toogleEmailModal(false);
  };
  const showEmailModal = () => {
    toogleEmailModal(true);
  };
  const [pageData, setPageData] = useState({
    loading: true,
    sessionLink: "",
    editAddressLink: "",
    taxID: "",
    endOfsubscription: 0,
    companyEmail: "",
    address: {
      name: "",
      line1: "",
      line2: "",
      postal_code: "",
      city: "",
      state: "",
      country: "",
    },
  });

  const getCustomerPortal = async () => {
    const options = {
      sessionID: orgSesionID,
      userStripeID: orgPaymentID,
      orgID: id,
    };
    await apiCall("user/getUserSettings", options, (response: any) => {
      setPageData({
        loading: false,
        sessionLink: response.portalSession.url,
        editAddressLink: response.userDetails.editAddressUrl,
        taxID: response.userDetails.tax,
        endOfsubscription: response.userDetails.endOfsubscription,
        companyEmail: response.orgMail,
        address: {
          name: response.userDetails.name,
          line1: response.userDetails.address.line1 || "",
          line2: response.userDetails.address.line2 || "",
          postal_code: response.userDetails.address.postal_code || "",
          city: response.userDetails.address.city || "",
          state: response.userDetails.address.state || "",
          country: response.userDetails.address.country || "",
        },
      });
    });
  };

  useEffect(() => {
    getCustomerPortal();
  }, []);

  const handleNameSave = async (orgName: string) => {
    const options = {
      orgID: id,
      value: orgName,
      property: "name",
    };

    await apiCall("organization/updateOrgDetail", options, (response: any) => {
      updateOrgName(orgName);
      hideNameModal();
    });
  };

  const handleEmailSave = async (email: string) => {
    const options = {
      orgID: id,
      value: email,
      property: "companyEmail",
    };
    await apiCall("organization/updateOrgDetail", options, (response: any) => {
      setPageData({
        ...pageData,
        companyEmail: email,
      });
      hideEmailModal();
    });
  };

  const handleBillingDataSave = async (
    companyAdressLine1: string,
    companyAdressLine2: string,
    companyCity: string,
    companyCountry: string,
    companyName: string,
    companyNip: number,
    companyPostCode: string
  ) => {
    const options = {
      customerId: orgPaymentID,
      companyAdressLine1,
      companyAdressLine2,
      companyCity,
      companyCountry,
      companyName,
      companyNip,
      companyPostCode,
    };
    await apiCall("user/updateBillingDetails", options, (response: any) => {
      setPageData({
        ...pageData,
        taxID: companyCountry + companyNip,
        address: {
          name: companyName,
          line1: companyAdressLine1,
          line2: companyAdressLine2,
          postal_code: companyPostCode,
          city: companyCity,
          state: "",
          country: companyCountry,
        },
      });
      hideBillingModal();
    });
  };
  if (pageData.loading) {
    return <LoadingContent />;
  }
  return (
    <Aligment direction="column" vPadding="10PX" align="flex-start">
      <Section noPadding={true}>
        <Aligment direction="column" align="flex-start">
          <SettingRow
            title={t(dictionary.organizationName)}
            onClick={showNameModal}
          >
            <Text>{name}</Text>
          </SettingRow>
          <SettingRow title={t(dictionary.activeSince)}>
            <Text>
              {format(new Date(creationTime), "dd-MMM-yyyy", { locale: pl })}
            </Text>
          </SettingRow>
          <SettingRow title={t(dictionary.licenses)}>
            <Text>
              {members.length} / {getLimits(planKey).users}
            </Text>
          </SettingRow>
          <SettingRow title={t(dictionary.usageSpace)}>
            <Text>
              {parseFloat(usedStorage.toFixed(3))} MB /{" "}
              {getLimits(planKey).space} MB
            </Text>
            <ProgressBar
              value={getPercentageValue(
                parseFloat(usedStorage.toFixed(3)),
                getLimits(planKey).space
              )}
            />
          </SettingRow>
        </Aligment>
      </Section>
      <Section title={t(dictionary.payment)} noPadding={true}>
        <Aligment direction="column" align="flex-start">
          <SettingRow title={t(dictionary.nextPay)}>
            <Text>
              {format(
                addDays(new Date(pageData.endOfsubscription), 1),
                "dd-MMM-yyyy",
                { locale: pl }
              )}
            </Text>
          </SettingRow>
          <SettingRow
            title={t(dictionary.companyEmail)}
            onClick={showEmailModal}
          >
            <Text>{pageData.companyEmail}</Text>
          </SettingRow>
          <SettingRow
            title={t(dictionary.billingAddress)}
            onClick={showBillingModal}
          >
            <Text>{pageData.address.name}</Text>
            <Text>{pageData.address.line1}</Text>
            <Text>{pageData.address.line2}</Text>
            <Text>
              {pageData.address.postal_code} {pageData.address.city}{" "}
              {pageData.address.country}
            </Text>
            <Text>{pageData.taxID}</Text>
          </SettingRow>
        </Aligment>
      </Section>
      {bilingModal && (
        <EditBillingModal
          onClose={hideBillingModal}
          handleSave={handleBillingDataSave}
          companyName={pageData.address.name}
          companyAdressLine1={pageData.address.line1}
          companyAdressLine2={pageData.address.line2}
          companyPostCode={pageData.address.postal_code}
          companyCity={pageData.address.city}
          companyCountry={pageData.address.country}
          companyNip={encryptNip(pageData.taxID)}
        />
      )}
      {nameModal && (
        <EditOrgNameModal
          orgName={name}
          onClose={hideNameModal}
          handleSave={handleNameSave}
        />
      )}
      {emailModal && (
        <EditOrgMailModal
          orgEmail={pageData.companyEmail}
          onClose={hideEmailModal}
          handleSave={handleEmailSave}
        />
      )}
    </Aligment>
  );
};
