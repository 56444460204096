import { FC, ReactText } from "react";
import {
  IntputWrapperBig,
  LoginFormSection,
  LoginSubtitle,
} from "../Login.styles";
import { Aligment, Dropdown, Input, Toogle } from "venice-ui";
import { t } from "../../../helpers/userHelper";
import { dictionary } from "../../../untils/dictionary";

interface IRegisterPaymentDetails {
  companyName: string;
  companyAdressLine1: string;
  companyAdressLine2: string;
  companyPostCode: string;
  companyCity: string;
  companyCountry: string;
  companyNip: number | undefined;
  companyEmail:string
  emailFlag: boolean;
  handleChange: (name: string, value: ReactText) => void;
  handleToogleChange: (value: boolean) => void;
}
export const countryScope = [{ value: "PL", label: t(dictionary.poland) }];

export const RegisterPaymentDetails: FC<IRegisterPaymentDetails> = ({
  companyName,
  companyAdressLine1,
  companyAdressLine2,
  companyPostCode,
  companyCity,
  companyCountry,
  companyNip,
  companyEmail,
  emailFlag,
  handleChange,
  handleToogleChange,
}) => {
  return (
    <>
      <LoginSubtitle>{t(dictionary.invoiceData)}</LoginSubtitle>
      <LoginFormSection>
        <IntputWrapperBig>
          <Input
            label={t(dictionary.companyName)}
            type="text"
            labelPosition="top"
            value={companyName}
            name="companyName"
            handleChange={(name: string, value: ReactText) =>
              handleChange(name, value as string)
            }
            autoFocus={true}
          />
        </IntputWrapperBig>
        <IntputWrapperBig>
          <Input
            label={t(dictionary.companyAdressLine1)}
            type="text"
            labelPosition="top"
            value={companyAdressLine1}
            name="companyAdressLine1"
            handleChange={(name: string, value: ReactText) =>
              handleChange(name, value as string)
            }
          />
        </IntputWrapperBig>
        <IntputWrapperBig>
          <Input
            label={t(dictionary.companyAdressLine2)}
            type="text"
            labelPosition="top"
            value={companyAdressLine2}
            name="companyAdressLine2"
            handleChange={(name: string, value: ReactText) =>
              handleChange(name, value as string)
            }
          />
        </IntputWrapperBig>
        <IntputWrapperBig>
          <Input
            label={t(dictionary.companyPostCode)}
            type="text"
            labelPosition="top"
            value={companyPostCode}
            name="companyPostCode"
            handleChange={(name: string, value: ReactText) =>
              handleChange(name, value as string)
            }
          />
        </IntputWrapperBig>
        <IntputWrapperBig>
          <Input
            label={t(dictionary.companyCity)}
            type="text"
            labelPosition="top"
            value={companyCity}
            name="companyCity"
            handleChange={(name: string, value: ReactText) =>
              handleChange(name, value as string)
            }
          />
        </IntputWrapperBig>
        <IntputWrapperBig>
          <Dropdown
            label={t(dictionary.companyCountry)}
            labelPosition="top"
            value={companyCountry}
            options={countryScope}
            handleSelect={(name: string, value: ReactText) =>
              handleChange(name, value as string)
            }
            name="companyCountry"
          />
        </IntputWrapperBig>
        <IntputWrapperBig>
          <Aligment direction="rcolumnow" wrap="nowrap">
            <Input
              label={t(dictionary.companyNip)}
              type="text"
              labelPosition="top"
              value={companyNip}
              name="companyNip"
              handleChange={(name: string, value: ReactText) =>
                handleChange(name, (value as string).trim())
              }
              prefix={companyCountry}
            />
          </Aligment>
        </IntputWrapperBig>
        <IntputWrapperBig>
          <Toogle
            label={t(dictionary.emailToggle)}
            labelPosition={"left"}
            handleClick={handleToogleChange}
            value={emailFlag}
          />
        </IntputWrapperBig>
        {emailFlag &&
        <IntputWrapperBig>
        <Aligment direction="rcolumnow" wrap="nowrap">
          <Input
            label={t(dictionary.companyEmail)}
            type="text"
            labelPosition="top"
            value={companyEmail}
            name="companyEmail"
            handleChange={(name: string, value: ReactText) =>
              handleChange(name, (value as string).trim())
            }
          />
        </Aligment>
      </IntputWrapperBig>
        }
      </LoginFormSection>
    </>
  );
};
