import React, { FC } from "react";
import { Progress, ProgressBarWrapper } from "./progressBar.styles";

interface IProgressBar {
  value: number;
}
export const ProgressBar: FC<IProgressBar> = ({ value }) => {
  return (
    <ProgressBarWrapper>
      <Progress value={value} />
    </ProgressBarWrapper>
  );
};
