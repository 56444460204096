import { t } from "../helpers/userHelper";
import { dictionary } from "../untils/dictionary";

export const ADDITIONAL_TYPES = {
  dot: "dot",
  edge: "edge",
  triage: "triage",
  cloud: "cloud",
};

export type TAdditionalType = "dot" | "edge" | "triage" | "cloud";

export interface IAddtionalBase {
  id: string;
  type: TAdditionalType;
  sectionID?: string;
  page: number;
  x: number;
  y: number;
  lock: boolean;
}
export interface IAddtionalDot extends IAddtionalBase {
  size: number;
}

export interface IAddtionalTriage extends IAddtionalBase {
  size: number;
  rotation: number;
}

export interface IAddtionalEdge extends IAddtionalBase {
  height: number;
  end_x: number;
  end_y: number;
}

export interface IAddtionalCloud extends IAddtionalBase {
  rotation: number;
  end_x: number;
  end_y: number;
}

export interface IAddtionalElements {
  id: string;
  type: TAdditionalType;
  sectionID?: string;
  page: number;
  x: number;
  y: number;
  lock: boolean;
  size: number;
  rotation: number;
  height: number;
  end_x: number;
  end_y: number;
}

export const getDotFormDefinition = (data?: IAddtionalDot): IAddtionalDot => {
  return {
    id: data?.id || "",
    type: data?.type || "dot",
    sectionID: data?.sectionID || "",
    page: data?.page || 0,
    x: data?.x || 0,
    y: data?.y || 0,
    lock: data?.lock || false,
    size: data?.size || 0,
  };
};
export const getDotForm = (state: IAddtionalDot) => {
  return [
    {
      type: "increase",
      label: t(dictionary.size),
      value: state.size,
      name: "size",
      step: 1,
    },
  ];
};

export const getTriageFormDefinition = (
  data?: IAddtionalTriage
): IAddtionalTriage => {
  return {
    id: data?.id || "",
    type: data?.type || "dot",
    sectionID: data?.sectionID || "",
    page: data?.page || 0,
    x: data?.x || 0,
    y: data?.y || 0,
    size: data?.size || 0,
    rotation: data?.rotation || 0,
    lock: data?.lock || false,
  };
};

export const getTriageForm = (state: IAddtionalTriage) => {
  return [
    {
      type: "increase",
      label: t(dictionary.size),
      value: state.size,
      name: "size",
      min: 2,
      step: 1,
    },
    {
      type: "increase",
      label: t(dictionary.rotation),
      value: state.rotation,
      name: "rotation",
      min: -360,
      step: 1,
      max: 360,
    },
  ];
};

export const getEdgeFormDefinition = (
  data?: IAddtionalEdge
): IAddtionalEdge => {
  return {
    id: data?.id || "",
    type: data?.type || "dot",
    sectionID: data?.sectionID || "",
    page: data?.page || 0,
    x: data?.x || 0,
    y: data?.y || 0,
    end_x: data?.end_x || 0,
    end_y: data?.end_y || 0,
    lock: data?.lock || false,
    height: data?.height || 0,
  };
};
export const getEdgeForm = (state: IAddtionalEdge) => {
  return [
    {
      type: "increase",
      label: t(dictionary.thickness),
      value: state.height,
      name: "height",
      min: 2,
      step: 1,
    },
  ];
};

export const getCloudFormDefinition = (
  data?: IAddtionalCloud
): IAddtionalCloud => {
  return {
    id: data?.id || "",
    type: data?.type || "dot",
    sectionID: data?.sectionID || "",
    page: data?.page || 0,
    x: data?.x || 0,
    y: data?.y || 0,
    end_x: data?.end_x || 0,
    end_y: data?.end_y || 0,
    lock: data?.lock || false,
    rotation: data?.rotation || 0,
  };
};
export const getCloudForm = (state: IAddtionalCloud) => {
  return [
    {
      type: "increase",
      label: t(dictionary.rotation),
      value: state.rotation,
      name: "rotation",
      min: -360,
      step: 1,
      max: 360,
    },
  ];
};
