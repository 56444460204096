import { FC, useEffect, useState } from "react";
import { Auth } from "./components/Auth/Auth";
import AppRouter from "./routers/AppRouter";
import { getLang } from "./helpers/userHelper";
import Cookies from "js-cookie";
import { apiURL, firebaseConfig } from "./untils/const";
import axios from "axios";
import { getAuth } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { IUserDetails } from "./store/interface/user";
import { emptyUser } from "./store/state/user";
import { useNavigate } from "react-router-dom";

export const AppContent: FC = () => {
  const [details, setDetails] = useState<IUserDetails>(emptyUser);
  const app = initializeApp(firebaseConfig);
  const auth = getAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const subscriber = auth.onAuthStateChanged(async (user) => {
      const registerFlag = localStorage.getItem("registerFlow") !== null;
      console.log("lisner onAuthStateChanged");
      if (!registerFlag) {
        if (user) {
          await axios
            .post(`${apiURL}/auth/getUserAuth`, {
              email: user.email,
              uid: user.uid,
            })
            .then((response: any) => {
              Cookies.set("idToken", response.data.token);
              const {
                accountType,
                email,
                lang,
                orgRole,
                contracts,
                flags,
                orgName,
                orgID,
                orgSesionID,
                orgPaymentID,
              } = response.data.userDetail;
              setDetails({
                uid: auth.currentUser!.uid,
                email: email,
                accountType: accountType,
                authenticated: true,
                flags: flags,
                lang: getLang(lang ? lang : navigator.language),
                contracts: contracts,
                loading: false,
                orgName: orgName,
                orgID: orgID,
                orgStatus: true,
                orgRole: orgRole,
                orgSesionID: orgSesionID,
                orgPaymentID: orgPaymentID,
              });
              localStorage.setItem(
                "lang",
                getLang(lang ? lang : navigator.language)
              );
              navigate(`/`);
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          setDetails({
            ...details,
            loading: false,
            lang: getLang(navigator.language),
          });
        }
      }
    });
    return () => {
      subscriber();
    }; // unsubscribe on unmount
  }, []);

  return (
    <Auth details={details}>
      <AppRouter />
    </Auth>
  );
};
