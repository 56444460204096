import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { DropdownMenu } from "venice-ui";
import { getAuth, signOut } from "firebase/auth";
import { Colors } from "../../untils/Theme";
import { t } from "../../helpers/userHelper";
import { appURL } from "../../untils/const";
import { dictionary } from "../../untils/dictionary";
import { useStoreActions, useStoreState } from "../../store";
import Cookies from "js-cookie";

export const Account: FC = () => {
  const navigate = useNavigate();
  const { email, orgRole } = useStoreState((state) => state.user.userDetails);
  const { clearUser } = useStoreActions((actions) => actions.user);
  const { clearProjects } = useStoreActions((actions) => actions.projects);
  const { resetProjectDetails } = useStoreActions(
    (actions) => actions.projectDetails
  );
  const { clearOrgDetails } = useStoreActions(
    (actions) => actions.organization
  );

  const auth = getAuth();
  const logout = () => {
    signOut(auth)
      .then(() => {
        localStorage.removeItem("lang");
        clearUser();
        clearProjects();
        resetProjectDetails();
        clearOrgDetails();
        Cookies.remove("idToken")
        window.location.href = appURL;
      })
      .catch((error) => {
        // An error happened.
      });
  };

  const links = [
    {
      action: () => navigate(`/settings`),
      label: t(dictionary.setting),
    },
  ];
  if (orgRole === "admin") {
    links.push({
      action: () => navigate(`/organization`),
      label: t(dictionary.organization),
    });
  }
  return (
    <DropdownMenu
      iconName="account"
      size="medium"
      iconColor={Colors.white}
      header={true}
      headerTitle={email}
      footer={true}
      footerAction={() => logout()}
      footerText={t(dictionary.logout)}
      options={links}
      position="right"
      zIndex={1100}
    />
  );
};
