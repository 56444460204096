import styled from "styled-components";
import { Colors } from "../../../untils/Theme";

export const ProgressBarWrapper = styled.div`
  width: 20rem;
  height: 1rem;
  background-color: ${Colors.appBlueAccent};
  border-radius: 0.5rem;
  overflow: hidden;
  display: flex;
  margin-top:0.4rem;
`;

interface IProgressProps {
  value: number;
}
export const Progress = styled.div<IProgressProps>`
  width: ${(p) => p.value}%;
  display: flex;
  height: 1rem;
  background-color: ${Colors.appBlue};
  ${(p) =>
    p.value > 90 &&
    `
    background-color: ${Colors.red};
    `}
`;
