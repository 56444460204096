import React, { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Content, ContentWrapper } from "../components";
import { apiCall } from "../../untils/apiCall";
import { EmptyState } from "../UI/EmptyState";
import { Aligment, Card, Form, Modal, Table } from "venice-ui";
import { LoadingContent } from "../layouts/Loading";
import {
  DeleteProjectModal,
  DeleteSectionModal,
  EditProjectData,
  EditProjectModal,
  EditSectionModal,
  ExportModal,
} from "../Modals";
import { t } from "../../helpers/userHelper";
import { IAction } from "venice-ui/dist/types/types";
import { getSectionObj } from "../../helpers/projectHelper";
import { EditSectionData } from "../Modals/EditSectionModal";
import { dictionary } from "../../untils/dictionary";
import { useStoreActions, useStoreState } from "../../store";
import { ProjectDetailsHeader } from "./ProjectDetailsHeader";
import { PointsList } from "./PointsList";
import { setShowListPoints } from "../Section/sectionModel";
import { xlsxListGenrator } from "../../helpers/generators";
import { pointsListHeaders } from "../../definitions/ListDefinition";

interface NewSectionProps {
  name: string;
  file: File | undefined;
  kks: string;
}

interface ISectinosPageProps {
  loading: boolean;
  actionLoader: boolean;
}

const ProjectDetails: FC = () => {
  const navigate = useNavigate();
  const [activeTab, updateActiveTab] = useState("sections");
  const tabActionClick = (value: string) => {
    updateActiveTab(value);
  };

  const { projectID } = useParams();
  const { uid, orgID } = useStoreState(
    (state) => state.user.userDetails
  );
  const {
    name: projectName,
    contract,
    sections,
    elements,
  } = useStoreState((state) => state.projectDetails.projectDetails);

  const [modal, toogleModal] = useState(false);
  const [pageProps, updatePageProps] = useState<ISectinosPageProps>({
    loading: true,
    actionLoader: false,
  });
  const [section, setSection] = useState<NewSectionProps>({
    name: "",
    file: undefined,
    kks: "",
  });

  const {
    setProjectDetails,
    setProjectName,
    addSection,
    updateSection,
    removeSection,
    resetProjectDetails,
  } = useStoreActions((actions) => actions.projectDetails);
  const { deleteProject: deleteProjectFromStore } = useStoreActions(
    (actions) => actions.projects
  );

  const [editProjectModal, toggleEditProjectModal] = useState(false);
  const [deleteProjectModal, toggleDeleteProjectModal] = useState(false);
  const [exportModal, toogleExportModal] = useState(false);

  const [deleteSectionModal, toggleDeleteSectionModal] = useState({
    show: false,
    id: "",
    sectionName: "",
  });

  const [editSectionModal, toggleEditSectionModal] = useState({
    show: false,
    id: "",
    sectionName: "",
    kks: "",
  });

  const showDeleteSectionModal = (id?: string) => {
    if (id) {
      const section = getSectionObj(sections, id);
      toggleDeleteSectionModal({
        show: true,
        id: id,
        sectionName: section.name,
      });
    }
  };

  const hideDeleteSectionModal = () => {
    toggleDeleteSectionModal({
      show: false,
      id: "",
      sectionName: "",
    });
  };

  const showEditSectionModal = (id?: string) => {
    if (id) {
      const section = getSectionObj(sections, id);
      toggleEditSectionModal({
        show: true,
        id: id,
        sectionName: section.name,
        kks: section.kks,
      });
    }
  };

  const hideEditSectoionModal = () => {
    toggleEditSectionModal({
      show: false,
      id: "",
      sectionName: "",
      kks: "",
    });
  };

  const getSecions = async () => {
    const options = {
      orgID: orgID,
      projectID: projectID,
    };
    await apiCall("projects/getProjectDetails", options, (response: any) => {
      setProjectDetails(response.details);
      updatePageProps({
        ...pageProps,
        loading: false,
      });
    });
  };

  useEffect(() => {
    getSecions();
  }, []);

  // const hideModal = () => {
  //   toogleModal(false);
  // };
  const showModal = () => {
    setSection({
      name: "",
      file: undefined,
      kks: "",
    });
    toogleModal(true);
  };
  const showExportModal = () => {
    toogleExportModal(true);
  };
  const hideExportModal = () => {
    toogleExportModal(false);
  };

  const deleteProject = async (id: string) => {
    await apiCall(
      "projects/removeProject",
      {
        userID: uid,
        projectID: id,
      },
      (response: any) => {
        resetProjectDetails();
        deleteProjectFromStore(id);
        navigate(`/`);
      }
    );
  };
  const deleteSection = async (id: string) => {
    const section = getSectionObj(sections, id);
    await apiCall(
      "projects/removeSection",
      {
        orgID: orgID,
        projectID: projectID,
        sectionID: id,
        fileName: section.file,
      },
      (response: any) => {
        updatePageProps({
          ...pageProps,
        });
        removeSection(id);
        hideDeleteSectionModal();
      }
    );
  };

  const addSectionFile = async () => {
    if (!pageProps.actionLoader) {
      updatePageProps({ ...pageProps, actionLoader: true });
      let formData = new FormData();
      formData.append("file", section!.file!);
      formData.append("sectionName", section!.name!);
      formData.append("kks", section!.kks!);
      formData.append("orgID", orgID);
      formData.append("projectID", projectID!);
      formData.append("updatedTime", Date.now().toString());
      formData.append("createdTime", Date.now().toString());

      await apiCall("projects/setNewSection", formData, (response: any) => {
        toogleModal(false);
        updatePageProps({ ...pageProps, actionLoader: false });
        addSection(response.newSection);
        navigate(`/project/${projectID}/${response.newSection.id}`);
      });
    }
  };

  const updateForm = (field: string, value: string | number | File) => {
    if (field === "file") {
      const fileValue = value as File;
      const kks = value ? fileValue.name.split(".pdf")[0] : "";
      setSection({ ...section, file: value as File, kks: kks });
    } else {
      setSection({ ...section, [field]: value });
    }
  };

  const onSubmitEditProject = (newData: EditProjectData) => {
    setProjectName({ name: newData.name, contract: newData.contract });
    toggleEditProjectModal(false);
  };

  const onSubmitEditSection = (newData: EditSectionData) => {
    console.log(newData);
    const sectionUpdates = {
      name: newData.name,
      kks: newData.kks,
      id: newData.sectionID,
    };
    updateSection(sectionUpdates);
    updatePageProps({
      ...pageProps,
      loading: false,
    });

    hideEditSectoionModal();
  };

  const validationNewSection = () => {
    const sectionName = section.name as string;
    return sectionName.trim().length === 0 || section.file === undefined;
  };
  const newSectionForm = {
    action: updateForm,
    data: [
      {
        type: "text",
        label: t(dictionary.sectionName),
        value: section?.name,
        name: "name",
        autofocus: true,
      },
      {
        type: "file",
        label: t(dictionary.pdfFile),
        value: section?.file,
        name: "file",
      },
      {
        type: "text",
        label: t(dictionary.kks),
        value: section?.kks,
        name: "kks",
      },
    ],
  };

  const headers = [
    {
      name: t(dictionary.sectionName),
      valueSource: "name",
    },
    {
      name: t(dictionary.kks),
      valueSource: "kks",
    },
    {
      name: t(dictionary.createDate),
      valueSource: "createdTime",
      date: true,
    },
  ];

  const moreActionsProject = [
    {
      label: t(dictionary.editProject),
      action: () => toggleEditProjectModal(true),
    },
    {
      label: t(dictionary.deleteProject),
      action: () => toggleDeleteProjectModal(true),
    },
  ];

  const moreActionsSection: IAction[] = [
    {
      label: t(dictionary.editSection),
      action: showEditSectionModal,
    },
    {
      label: t(dictionary.deleteSection),
      action: showDeleteSectionModal,
    },
  ];

  const selectSection = async (id: string) => {
    navigate(`/project/${projectID}/${id}`);
  };
  const handleExport = () => {
    xlsxListGenrator(
      setShowListPoints(elements, sections),
      pointsListHeaders,
      projectName
    );
    toogleExportModal(false);
  };

  if (pageProps.loading) {
    return <LoadingContent />;
  }
  return (
    <Content>
      <Card
        title={projectName}
        shadow={false}
        actionLabel={
          activeTab === "sections"
            ? t(dictionary.addSection)
            : t(dictionary.export)
        }
        handleClick={activeTab === "sections" ? showModal : showExportModal}
        handleBack={() => navigate(`/`)}
        options={moreActionsProject}
        height="calc(100vh - 6rem)"
      >
        <Aligment align="stretch" direction="row">
          <ProjectDetailsHeader
            activeTab={activeTab}
            setActiveTab={tabActionClick}
          />
          {activeTab === "sections" && (
            <>
              {sections.length > 0 ? (
                <ContentWrapper height="calc(100vh - 15.4rem)">
                  <Table
                    headers={headers}
                    elements={sections}
                    onRowClick={selectSection}
                    selectable={false}
                    sortable={false}
                    filtrable={false}
                    moreActions={moreActionsSection}
                  ></Table>
                </ContentWrapper>
              ) : (
                <EmptyState
                  title={t(dictionary.emptyStateSectionTitle)}
                  actionLabel={t(dictionary.addSection)}
                  action={() => showModal()}
                />
              )}
            </>
          )}
          {activeTab === "log" && <PointsList />}
        </Aligment>
      </Card>
      {modal && (
        <Modal
          title={t(dictionary.newSection)}
          handleClose={() => toogleModal(false)}
          handleConfirm={() => addSectionFile()}
          labelClose={t(dictionary.cancel)}
          labelConfirm={t(dictionary.createSection)}
          submitDisabled={validationNewSection()}
          submitLoader={pageProps.actionLoader}
        >
          <Form formData={newSectionForm} />
        </Modal>
      )}
      {editProjectModal && (
        <EditProjectModal
          onClose={() => toggleEditProjectModal(false)}
          onConfirm={onSubmitEditProject}
          dataSet={{
            id: projectID ? projectID : "",
            contract: contract,
            name: projectName,
            show: true,
          }}
        />
      )}
      {deleteProjectModal && (
        <DeleteProjectModal
          handleCancel={() => toggleDeleteProjectModal(false)}
          handleDelete={deleteProject}
          id={projectID!}
          name={projectName}
        />
      )}
      {editSectionModal.show && (
        <EditSectionModal
          onClose={() => hideEditSectoionModal()}
          onConfirm={onSubmitEditSection}
          dataSet={{
            sectionID: editSectionModal.id,
            projectID: projectID!,
            name: editSectionModal.sectionName,
            kks: editSectionModal.kks,
            show: true,
          }}
        />
      )}
      {deleteSectionModal.show && (
        <DeleteSectionModal
          handleCancel={() => hideDeleteSectionModal()}
          handleDelete={deleteSection}
          id={deleteSectionModal.id}
          name={deleteSectionModal.sectionName}
        />
      )}

      {exportModal && (
        <ExportModal
          handleCancel={hideExportModal}
          handleConfirm={handleExport}
          name={projectName}
        />
      )}
    </Content>
  );
};

export default ProjectDetails;
