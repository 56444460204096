import React, { FC, ReactText } from "react";
import { Input } from "venice-ui";
import { IntputWrapperBig, LoginFormSection, LoginSubtitle } from "../Login.styles";
import { t } from "../../../helpers/userHelper";
import { dictionary } from "../../../untils/dictionary";

interface IRegisterEmailProps {
  loginValue: string;
  orgNameValue: string;
  handleChange: (name: string, value: ReactText) => void;
  handleSubmit:()=>void
  isJoinMode:boolean
}
export const RegisterEmail: FC<IRegisterEmailProps> = ({
  loginValue,
  orgNameValue,
  handleChange,
  handleSubmit,
  isJoinMode
}) => {
  
  
  return (
    <>
      <LoginSubtitle>{t(dictionary.loginTitle)}</LoginSubtitle>
      <LoginFormSection>
        <IntputWrapperBig>
          <Input
            label={t(dictionary.inputLabelEmail)}
            type="text"
            labelPosition="top"
            value={loginValue}
            name="login"
            handleChange={(name: string, value: ReactText) =>
              handleChange(name, (value as string).trim())
            }
            autoFocus={true}
            handleSubmit={handleSubmit}
          />
        </IntputWrapperBig>
        {!isJoinMode &&
        
        <IntputWrapperBig>
          <Input
            label={t(dictionary.inputLabelOrganization)}
            type="text"
            labelPosition="top"
            value={orgNameValue}
            name="organizationName"
            handleChange={(name: string, value: ReactText) =>
              handleChange(name, (value as string))
            }
            handleSubmit={handleSubmit}
          />
        </IntputWrapperBig>
        }
      </LoginFormSection>
    </>
  );
};
