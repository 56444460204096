import React, { FC, useEffect, useState } from "react";
import { Content } from "../../components/components";
import { Aligment, Card } from "venice-ui";
import { dictionary } from "../../untils/dictionary";
import { t } from "../../helpers/userHelper";
import { OrganizationDetails } from "../../components/Organization/OrganizationDetails";
import { apiCall } from "../../untils/apiCall";
import { useStoreActions, useStoreState } from "../../store";
import { OrganizationUsers } from "../../components/Organization/OrganizationUsers";
import { OrganizationsContracts } from "../../components/Organization/OrganizationsContracts";
import { Tabs } from "../../components/UI/Tabs";
import { useNavigate, useParams } from "react-router-dom";
import { ContractModal } from "../../components/Modals/ContractModal";
import { UserLimitModal } from "../../components/Modals/UserLimitModal";
import { UserModal } from "../../components/Modals/UserModal";
import { checkUserLimit } from "../../helpers/organizationHelper";

export const Organization: FC = () => {
  const { tab, action } = useParams();

  const { uid, orgID } = useStoreState((state) => state.user.userDetails);
  const { members, planKey, invitations } = useStoreState(
    (state) => state.organization
  );

  const [activeTab, setActiveTab] = useState(tab ? tab : "org");
  const [showContractModal, toogleShowContractModal] = useState(
    action ? true : false
  );
  const [showUserModal, toogleShowUserModal] = useState(false);

  const { setOrgDetails } = useStoreActions((actions) => actions.organization);

  const navigate = useNavigate();

  const getOrgDetails = async () => {
    const options = {
      orgID: orgID,
    };
    await apiCall(
      "organization/getOrganizationDetails",
      options,
      (response: any) => {
        setOrgDetails(response.details);
      }
    );
  };

  useEffect(() => {
    getOrgDetails();
  }, []);

  const hideAddContractModal = () => {
    toogleShowContractModal(false);
  };
  const showAddContractModal = () => {
    toogleShowContractModal(true);
  };

  const hideUserModalModal = () => {
    toogleShowUserModal(false);
  };
  const showUserModalModal = () => {
    toogleShowUserModal(true);
  };

  const getHeaderAction = () => {
    if (activeTab === "contrats") {
      showAddContractModal();
    } else if (activeTab === "users") {
      showUserModalModal();
    }
  };
  const getHeaderLabel = () => {
    if (activeTab === "contrats") {
      return t(dictionary.addContract);
    } else if (activeTab === "users") {
      return t(dictionary.addUser);
    }
  };

  const orgTabs = [
    {
      value: "org",
      label: t(dictionary.details),
    },
    {
      value: "users",
      label: t(dictionary.users),
    },
    {
      value: "contrats",
      label: t(dictionary.contracts),
    },
  ];
  return (
    <Content>
      <Card
        title={"Organizacja"}
        shadow={false}
        actionLabel={getHeaderLabel()}
        height="calc(100vh - 6rem)"
        handleClick={getHeaderAction}
        handleBack={() => navigate("/")}
      >
        <Aligment direction="column" align="flex-start" gap={10}>
          <Tabs tabs={orgTabs} activeValue={activeTab} onClick={setActiveTab} />
          {activeTab === "org" && <OrganizationDetails />}
          {activeTab === "users" && <OrganizationUsers />}
          {activeTab === "contrats" && (
            <OrganizationsContracts handleClick={showAddContractModal} />
          )}
        </Aligment>
      </Card>
      {showContractModal && (
        <ContractModal handleClose={hideAddContractModal} />
      )}
      {showUserModal &&
        (checkUserLimit(planKey, members.length + invitations.length) ? (
          <UserModal handleClose={hideUserModalModal} />
        ) : (
          <UserLimitModal handleClose={hideUserModalModal} />
        ))}
    </Content>
  );
};
