import { t } from "../helpers/userHelper";
import { dictionary } from "../untils/dictionary";
import { IItemType, resultScope } from "./element";

export interface IJointType extends IItemType {
  joint_type: string;
  element_type: string;
  diameter_1: number;
  diameter_2: number;
  thickness_1: number;
  thickness_2: number;
  material_1: string;
  material_2: string;
  sourceElementID?: string;
  welder: string;
  welding_date: number | undefined;
  melt_number_1: string;
  melt_number_2: string;
  vt_examination_number: string;
  vt_examination_date: number | undefined;
  vt_examination_result: string;
  rt_examination_number: string;
  rt_examination_date: number | undefined;
  rt_examination_result: string;
  pt_examination_number: string;
  pt_examination_date: number | undefined;
  pt_examination_result: string;
  ut_examination_number: string;
  ut_examination_date: number | undefined;
  ut_examination_result: string;
  mt_examination_number: string;
  mt_examination_date: number | undefined;
  mt_examination_result: string;
}

export const getJointDefinition = (data?: IJointType): IJointType => {
  return {
    id: data?.id || "",
    name: data?.name || "",
    sectionID: data?.sectionID || "",
    page: data?.page || 0,
    x: data?.x || 0,
    y: data?.y || 0,
    label_x: data?.label_x || 0,
    label_y: data?.label_y || 0,
    type: data?.type || "joint",
    lock: data?.lock || false,
    joint_type: data?.joint_type || "",
    element_type: data?.element_type || "",
    diameter_1: data?.diameter_1 || 0,
    diameter_2: data?.diameter_2 || 0,
    thickness_1: data?.thickness_1 || 0,
    thickness_2: data?.thickness_2 || 0,
    material_1: data?.material_1 || "",
    material_2: data?.material_2 || "",
    createdDate: data?.createdDate || 0,
    sourceElementID: data?.sourceElementID || undefined,
    welder: data?.welder || "",
    welding_date: data?.welding_date || undefined,
    melt_number_1: data?.melt_number_1 || "",
    melt_number_2: data?.melt_number_2 || "",
    vt_examination_number: data?.vt_examination_number || "",
    vt_examination_date: data?.vt_examination_date || undefined,
    vt_examination_result: data?.vt_examination_result || "",
    rt_examination_number: data?.rt_examination_number || "",
    rt_examination_date: data?.rt_examination_date || undefined,
    rt_examination_result: data?.rt_examination_result || "",
    pt_examination_number: data?.pt_examination_number || "",
    pt_examination_date: data?.pt_examination_date || undefined,
    pt_examination_result: data?.pt_examination_result || "",
    ut_examination_number: data?.ut_examination_number || "",
    ut_examination_date: data?.ut_examination_date || undefined,
    ut_examination_result: data?.ut_examination_result || "",
    mt_examination_number: data?.mt_examination_number || "",
    mt_examination_date: data?.mt_examination_date || undefined,
    mt_examination_result: data?.mt_examination_result || "",
  };
};

export interface IJointFormType extends IItemType {
  joint_type: string;
  element_type: string;
  diameter_1: string;
  diameter_2: string;
  thickness_1: string;
  thickness_2: string;
  material_1: string;
  material_2: string;
  sourceElementID?: string;
  welder: string;
  welding_date: number | undefined;
  melt_number_1: string;
  melt_number_2: string;
  vt_examination_number: string;
  vt_examination_date: number | undefined;
  vt_examination_result: string;
  rt_examination_number: string;
  rt_examination_date: number | undefined;
  rt_examination_result: string;
  pt_examination_number: string;
  pt_examination_date: number | undefined;
  pt_examination_result: string;
  ut_examination_number: string;
  ut_examination_date: number | undefined;
  ut_examination_result: string;
  mt_examination_number: string;
  mt_examination_date: number | undefined;
  mt_examination_result: string;
}

export const getJointFormDefinition = (data?: IJointType): IJointFormType => {
  return {
    id: data?.id || "",
    name: data?.name || "",
    sectionID: data?.sectionID || "",
    page: data?.page || 0,
    x: data?.x || 0,
    y: data?.y || 0,
    label_x: data?.label_x || 0,
    label_y: data?.label_y || 0,
    type: data?.type || "joint",
    lock: data?.lock || false,
    joint_type: data?.joint_type || "",
    element_type: data?.element_type || "",
    diameter_1: data?.diameter_1.toString() || "",
    diameter_2: data?.diameter_2.toString() || "",
    thickness_1: data?.thickness_1.toString() || "",
    thickness_2: data?.thickness_2.toString() || "",
    material_1: data?.material_1 || "",
    material_2: data?.material_2 || "",
    createdDate: data?.createdDate || 0,
    sourceElementID: data?.sourceElementID || undefined,
    welder: data?.welder || "",
    welding_date: data?.welding_date || undefined,
    melt_number_1: data?.melt_number_1 || "",
    melt_number_2: data?.melt_number_2 || "",
    vt_examination_number: data?.vt_examination_number || "",
    vt_examination_date: data?.vt_examination_date || undefined,
    vt_examination_result: data?.vt_examination_result || "",
    rt_examination_number: data?.rt_examination_number || "",
    rt_examination_date: data?.rt_examination_date || undefined,
    rt_examination_result: data?.rt_examination_result || "",
    pt_examination_number: data?.pt_examination_number || "",
    pt_examination_date: data?.pt_examination_date || undefined,
    pt_examination_result: data?.pt_examination_result || "",
    ut_examination_number: data?.ut_examination_number || "",
    ut_examination_date: data?.ut_examination_date || undefined,
    ut_examination_result: data?.ut_examination_result || "",
    mt_examination_number: data?.mt_examination_number || "",
    mt_examination_date: data?.mt_examination_date || undefined,
    mt_examination_result: data?.mt_examination_result || "",
  };
};

export const getJointForm = (state: IJointType) => {
  return [
    {
      row: [
        {
          type: "text",
          label: t(dictionary.jointNumber),
          value: state.name,
          name: "name",
        },
      ],
    },
    {
      row: [
        {
          type: "text",
          label: t(dictionary.jointType),
          value: state.joint_type,
          name: "joint_type",
        },
        {
          type: "text",
          label: t(dictionary.element_type),
          value: state.element_type,
          name: "element_type",
        },
      ],
    },
    {
      row: [
        {
          type: "number",
          label: t(dictionary.diameter_1),
          value: state.diameter_1,
          name: "diameter_1",
        },
        {
          type: "number",
          label: t(dictionary.diameter_2),
          value: state.diameter_2,
          name: "diameter_2",
        },
      ],
    },
    {
      row: [
        {
          type: "number",
          label: t(dictionary.thickness_1),
          value: state.thickness_1,
          name: "thickness_1",
        },
        {
          type: "number",
          label: t(dictionary.thickness_2),
          value: state.thickness_2,
          name: "thickness_2",
        },
      ],
    },
    {
      row: [
        {
          type: "text",
          label: t(dictionary.material_1),
          value: state.material_1,
          name: "material_1",
        },
        {
          type: "text",
          label: t(dictionary.material_2),
          value: state.material_2,
          name: "material_2",
        },
      ],
    },
  ];
};

export const getFullJointForm = (state: IJointType) => {
  return [
    {
      row: [
        {
          type: "text",
          label: t(dictionary.jointNumber),
          value: state.name,
          name: "name",
        },
      ],
    },
    {
      row: [
        {
          type: "text",
          label: t(dictionary.jointType),
          value: state.joint_type,
          name: "joint_type",
        },
        {
          type: "text",
          label: t(dictionary.element_type),
          value: state.element_type,
          name: "element_type",
        },
      ],
    },
    {
      row: [
        {
          type: "number",
          label: t(dictionary.diameter_1),
          value: state.diameter_1,
          name: "diameter_1",
        },
        {
          type: "number",
          label: t(dictionary.diameter_2),
          value: state.diameter_2,
          name: "diameter_2",
        },
      ],
    },
    {
      row: [
        {
          type: "number",
          label: t(dictionary.thickness_1),
          value: state.thickness_1,
          name: "thickness_1",
        },
        {
          type: "number",
          label: t(dictionary.thickness_2),
          value: state.thickness_2,
          name: "thickness_2",
        },
      ],
    },
    {
      row: [
        {
          type: "text",
          label: t(dictionary.material_1),
          value: state.material_1,
          name: "material_1",
        },
        {
          type: "text",
          label: t(dictionary.material_2),
          value: state.material_2,
          name: "material_2",
        },
      ],
    },
    {
      row: [
        {
          type: "text",
          label: t(dictionary.welder),
          value: state.welder,
          name: "welder",
        },
        {
          type: "date",
          label: t(dictionary.weldingDate),
          value: state.welding_date,
          name: "weldingDate",
          zIndex: 9999,
        },
      ],
    },
    {
      row: [
        {
          type: "text",
          label: t(dictionary.meltNumber_1),
          value: state.melt_number_1,
          name: "melt_number_1",
        },
        {
          type: "text",
          label: t(dictionary.meltNumber_2),
          value: state.melt_number_2,
          name: "melt_number_2",
        },
      ],
    },
    {
      row: [
        {
          type: "text",
          label: t(dictionary.vTExaminationNumber),
          value: state.vt_examination_number,
          name: "vt_examination_number",
        },
        {
          type: "date",
          label: t(dictionary.vTExaminationDate),
          value: state.vt_examination_date,
          name: "vt_examination_date",
          zIndex: 9999,
        },
        {
          type: "select",
          label: t(dictionary.vTExaminationResult),
          value: state.vt_examination_result,
          name: "vt_examination_result",
          options: resultScope,
          zIndex: 9999,
        },
      ],
    },
    {
      row: [
        {
          type: "text",
          label: t(dictionary.rTExaminationNumber),
          value: state.rt_examination_number,
          name: "rt_examination_number",
        },
        {
          type: "date",
          label: t(dictionary.rTExaminationDate),
          value: state.rt_examination_date,
          name: "rt_examination_date",
          zIndex: 9999,
        },
        {
          type: "select",
          label: t(dictionary.rTExaminationResult),
          value: state.rt_examination_result,
          name: "rt_examination_result",
          options: resultScope,
          zIndex: 9999,
        },
      ],
    },
    {
      row: [
        {
          type: "text",
          label: t(dictionary.pTExaminationNumber),
          value: state.pt_examination_number,
          name: "pt_examination_number",
        },
        {
          type: "date",
          label: t(dictionary.pTExaminationDate),
          value: state.pt_examination_date,
          name: "pt_examination_date",
          zIndex: 9999,
        },
        {
          type: "select",
          label: t(dictionary.pTExaminationResult),
          value: state.pt_examination_result,
          name: "pt_examination_result",
          options: resultScope,
          zIndex: 9999,
        },
      ],
    },
    {
      row: [
        {
          type: "text",
          label: t(dictionary.uTExaminationNumber),
          value: state.ut_examination_number,
          name: "ut_examination_number",
        },
        {
          type: "date",
          label: t(dictionary.uTExaminationDate),
          value: state.ut_examination_date,
          name: "ut_examination_date",
          zIndex: 9999,
        },
        {
          type: "select",
          label: t(dictionary.uTExaminationResult),
          value: state.ut_examination_result,
          name: "ut_examination_result",
          options: resultScope,
          zIndex: 9999,
        },
      ],
    },
    {
      row: [
        {
          type: "text",
          label: t(dictionary.mTExaminationNumber),
          value: state.mt_examination_number,
          name: "mt_examination_number",
        },
        {
          type: "date",
          label: t(dictionary.mTExaminationDate),
          value: state.mt_examination_date,
          name: "mt_examination_date",
          zIndex: 9999,
        },
        {
          type: "select",
          label: t(dictionary.mTExaminationResult),
          value: state.mt_examination_result,
          name: "mt_examination_result",
          options: resultScope,
          zIndex: 9999,
        },
      ],
    },
  ];
};

export const getJointDetails = (state: IJointType) => {
  return [
    {
      label: t(dictionary.jointNumber),
      value: state.name,
      type: "text",
    },
    {
      label: t(dictionary.jointType),
      value: state.joint_type,
      type: "text",
    },
    {
      label: t(dictionary.element_type),
      value: state.element_type,
      type: "text",
    },
    {
      label: t(dictionary.diameter_1),
      value: state.diameter_1,
      type: "number",
    },
    {
      label: t(dictionary.diameter_2),
      value: state.diameter_2,
      type: "number",
    },
    {
      label: t(dictionary.thickness_1),
      value: state.thickness_1,
      type: "number",
    },
    {
      label: t(dictionary.thickness_2),
      value: state.thickness_2,
      type: "number",
    },
    {
      label: t(dictionary.material_1),
      value: state.material_1,
      type: "text",
    },
    {
      label: t(dictionary.material_2),
      value: state.material_2,
      type: "text",
    },
  ];
};
export const jointValidatorFileds: string[] = [
  "name",
  "joint_type",
  "element_type",
  "material_1",
  "material_2",
  "diameter_1",
  "diameter_2",
  "thickness_1",
  "thickness_2",
];
