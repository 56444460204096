import React, { FC, useState } from "react";
import { Modal, Form } from "venice-ui";
import { t } from "../../helpers/userHelper";
import { dictionary } from "../../untils/dictionary";

interface IEditOrgNameModal {
  orgName: string;
  onClose: () => void;
  handleSave: (name: string) => void;
}
export const EditOrgNameModal: FC<IEditOrgNameModal> = ({
  orgName,
  onClose,
  handleSave,
}) => {
  const [name, setName] = useState(orgName);

  const updateOrgName = () => {
    handleSave(name);
  };
  const updateValue = (name: string, value: string) => {
    setName(value);
  };
  const editForm = {
    action: updateValue,
    data: [
      {
        row: [
          {
            type: "text",
            label: t(dictionary.organizationName),
            value: name,
            name: "name",
            autofocus: true,
            handleSave:() =>updateOrgName()
          },
        ],
      },
    ],
  };
  return (
    <Modal
      title={`${t(dictionary.editOrgNameModalTitle)}`}
      handleClose={onClose}
      handleConfirm={updateOrgName}
      labelClose={t(dictionary.cancel)}
      labelConfirm={t(dictionary.save)}
      submitDisabled={name.trim() === ""}
    >
      <Form formData={editForm} />
    </Modal>
  );
};
