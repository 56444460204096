import React, { FC, useState } from "react";
import { Aligment, Button, Icon, Input, Modal, Section } from "venice-ui";
import { IOrganizations } from "../../types/organizationsTypes";
import { useStoreState } from "../../store";
import { t } from "../../helpers/userHelper";
import { dictionary } from "../../untils/dictionary";

interface IOrgDetails {
  orgId: string;
  handleClose: () => void;
}
export const OrgDetails: FC<IOrgDetails> = ({ orgId, handleClose }) => {
  const { organizations } = useStoreState((state) => state.panel);
  const { uid } = useStoreState((state) => state.user.userDetails);

  const getOrgDetails = (orgId: string, organizations: IOrganizations[]) => {
    return organizations.find((item) => item.id === orgId)!;
  };

  const [details, setDetails] = useState<IOrganizations>(
    getOrgDetails(orgId, organizations)
  );

  const [member, setMember] = useState({
    memberID: "",
    show: false,
  });

  const hideMemberArea = () => {
    setMember({ show: false, memberID: "" });
  };

  const toogleMemberArea = () => {
    setMember({ ...member, show: !member.show });
  };

  const onMemberChange = (name: string, value: string | number) => {
    setMember({ ...member, memberID: value.toString().trim() });
  };
//ToDo to delete
  const addMemeber = async () => {
    const options = {
      userID: uid,
      memberID: member.memberID,
      orgID: orgId,
    };
    // await apiCall("admin/addMemberToOrg", options, (response: any) => {
    //   console.log("aaaa");
    //   hideMemberArea();
    //   //   const newOrg: IOrganizations = {
    //   //     ...orgDetails,
    //   //     id: response.newOrganizationID,
    //   //   };
    //   // addNewOrgToList(newOrg);
    // });
  };
  return (
    <Modal
      handleClose={handleClose}
      labelClose={"zamknij"}
      title={details.name}
    >
      {member.show ? (
        <Aligment gap={16} direction="row">
          <Input
            handleChange={onMemberChange}
            value={member.memberID}
            name={"memberID"}
          />
          <Aligment justify="flex-end" gap={16}>
            <Button
              mode="secondary"
              text={"anuluj"}
              onClick={toogleMemberArea}
            />
            <Button text={"dodaj"} onClick={addMemeber} />
          </Aligment>
        </Aligment>
      ) : (
        <Icon  onClick={toogleMemberArea} name={"person"} />
        
      )}
      <Section title={t(dictionary.users)} noPadding={true}>
        <Aligment gap={10}></Aligment>
      </Section>
    </Modal>
  );
};
