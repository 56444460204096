import React, { FC  } from "react";
import { Content } from "../components";
import { PaymentInfo } from "./Partial";
import { PAYMENT_MODE } from "../../helpers/settingsHelper";

export const SuccessPayment: FC = () => {
  return (
    <Content>
      <PaymentInfo mode={PAYMENT_MODE.success} />
    </Content>
  );
};
