import React, { FC, useEffect, useState } from "react";
import { Aligment, Button, TextSmall } from "venice-ui";
import {
  ErrorArea,
  LoginActionLink,
  LoginFooterSection,
  LoginFormSection,
  LoginTite,
} from "./Login.styles";
import {
  errorMsg,
  LOGIN_MODE,
  TLoginMode,
} from "../../helpers/loginHelper";
import { apiCall } from "../../untils/apiCall";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { createUser, joinUser, t } from "../../helpers/userHelper";
import {
  RegisterEmail,
  RegisterPassword,
  RegisterVerificationCode,
} from "./Partial";
import { checkCompanyDetails, checkStatus, emailFormatCheck } from "../PasswordCheck/checks";
import { dictionary } from "../../untils/dictionary";
import { useNavigate, useParams } from "react-router-dom";
import { IPracingProps } from "./register.types";
import { RegisterPlan } from "./Partial/RegisterPlan";
import { RegisterPaymentDetails } from "./Partial/RegisterPaymentDetails";

interface IRegisterFormProps {
  changeMode: (mode: TLoginMode) => void;
}

interface ICreateUserProps {
  login: string;
  organizationName: string;
  password: string;
  verifyCode: string;
  codeID: string;
  loading: boolean;
  error: boolean;
  errorCode: string;
  step: number;
  probe: number;
  orgPlanID: string;
  orgPlanName: string;
  companyName: string;
  companyAdressLine1: string;
  companyAdressLine2: string;
  companyPostCode: string;
  companyCity: string;
  companyCountry: string;
  companyNip: number | undefined;
  companyEmail: string;
  emailFlag: boolean;
}

export const RegisterForm: FC<IRegisterFormProps> = ({ changeMode }) => {
  const { pageMode, code } = useParams();

  const isJoinMode = pageMode !== undefined && pageMode === "join";
  const navigate = useNavigate();

  const [pricePlans, setPricePlans] = useState<IPracingProps[]>([]);

  const getPricing = async () => {
    const options = {};

    await apiCall("payments/getPricing", options, (response: any) => {
      if (response.error) {
        console.log(response.error);
        navigate("/server-error");
      }
      setPricePlans(response.pricing);
    });
  };

  useEffect(() => {
    getPricing();
  }, []);

  const [sectionData, updateSectionData] = useState<ICreateUserProps>({
    login: "",
    organizationName: "",
    password: "",
    verifyCode: "",
    codeID: "",
    loading: false,
    error: false,
    errorCode: "",
    step: 0,
    probe: 0,
    orgPlanID: "",
    orgPlanName: "",
    companyName: "",
    companyAdressLine1: "",
    companyAdressLine2: "",
    companyPostCode: "",
    companyCity: "",
    companyCountry: "PL",
    companyNip: undefined,
    companyEmail: "",
    emailFlag: false,
  });

  const setValue = (field: string, value: string) => {
    updateSectionData({
      ...sectionData,
      [field]: value,
    });
  };

  const verifyEmailAddress = async () => {
    const joinChecker = isJoinMode || sectionData.organizationName.length > 0;
    if (sectionData.login.length > 0 && joinChecker) {
      if (emailFormatCheck(sectionData.login)) {
        const options = {
          email: sectionData.login.trim(),
          invitationID: code,
        };

        await apiCall("user/setVeryfiCode", options, (response: any) => {
          if (response.data.error) {
            updateSectionData({
              ...sectionData,
              error: true,
              errorCode: response.data.errorMsg,
              loading: false,
            });
          } else {
            updateSectionData({
              ...sectionData,
              error: false,
              errorCode: "",
              loading: false,
              codeID: response.data.codeID,
              verifyCode: "",
              step: 1,
              probe: 0,
            });
          }
        });
      } else {
        updateSectionData({
          ...sectionData,
          error: true,
          errorCode: "wrong-email",
          loading: false,
        });
      }
    }
  };
  const verifyCode = async () => {
    const options = {
      codeID: sectionData.codeID,
      code: sectionData.verifyCode,
    };
    await apiCall("user/checkVeryfiCode", options, (response: any) => {
      if (response.check) {
        updateSectionData({
          ...sectionData,
          error: false,
          errorCode: "",
          loading: false,
          step: 2,
          probe: 0,
        });
      } else {
        updateSectionData({
          ...sectionData,
          error: true,
          errorCode: "wrong-code",
          loading: false,
          probe: sectionData.probe + 1,
        });
      }
    });
  };

  const auth = getAuth();
  const registerUser = async () => {
    let checker = true;
    if (sectionData.emailFlag) {
      if (!emailFormatCheck(sectionData.companyEmail)) {
        updateSectionData({
          ...sectionData,
          error: true,
          errorCode: "wrong-email",
          loading: false,
        });
      }
    }
    if (checker) {
      localStorage.setItem("registerFlow", "enable");
      await createUserWithEmailAndPassword(
        auth,
        sectionData.login,
        sectionData.password
      )
        .then(async (userCredential) => {
          if (isJoinMode) {
            await joinUser(
              sectionData.login,
              userCredential.user.uid,
              code !== undefined ? code : ""
            );
          } else {
            await createUser(
              sectionData.login,
              userCredential.user.uid,
              sectionData.orgPlanID,
              sectionData.organizationName.trim(),
              sectionData.companyName.trim(),
              sectionData.companyAdressLine1,
              sectionData.companyAdressLine2,
              sectionData.companyPostCode,
              sectionData.companyCity,
              sectionData.companyCountry,
              sectionData.companyCountry + sectionData.companyNip,
              sectionData.companyEmail.trim()
            );
          }
        })
        .catch((error: Error) => {
          console.log(error);
        });
    }
  };

  const goToSelectPlan = () => {
    updateSectionData({
      ...sectionData,
      step: 3,
    });
  };
  const goToComapnyDetails = () => {
    updateSectionData({
      ...sectionData,
      companyEmail: sectionData.login,
      step: 4,
    });
  };

  const selectUserPlan = (planID: string, planName: string) => {
    updateSectionData({
      ...sectionData,
      orgPlanID: planID,
      orgPlanName: planName,
    });
  };

  const nextAction = async () => {
    if (!sectionData.loading) {
      updateSectionData({
        ...sectionData,
        error: false,
        errorCode: "",
        loading: true,
      });
      if (sectionData.step === 0) {
        verifyEmailAddress();
      } else if (sectionData.step === 1) {
        verifyCode();
      } else if (sectionData.step === 2) {
        isJoinMode ? registerUser() : goToSelectPlan();
      } else if (sectionData.step === 3) {
        goToComapnyDetails();
      } else if (sectionData.step === 4) {
        registerUser();
      }
    }
  };
  const setButtonText = (): string => {
    if (sectionData.step === 0) {
      return t(dictionary.next);
    } else if (sectionData.step === 1) {
      return t(dictionary.verify);
    } else if (sectionData.step === 2) {
      return isJoinMode ? t(dictionary.createAnAccount) : t(dictionary.next);
    } else if (sectionData.step === 3) {
      return t(dictionary.next);
    } else if (sectionData.step === 4) {
      return t(dictionary.createAnAccount);
    }
    return t(dictionary.next);
  };

  const checkDisable = () => {
    let status = false;
    if (sectionData.step === 0) {
      if (isJoinMode) {
        status = sectionData.login === "";
      } else {
        status =
          sectionData.login === "" || sectionData.organizationName === "";
      }
    } else if (sectionData.step === 2) {
      status = checkStatus(sectionData.password);
    } else if (sectionData.step === 3) {
      status = sectionData.orgPlanID === "";
    } else if (sectionData.step === 4) {
      status = checkCompanyDetails(
        sectionData.companyName,
        sectionData.companyAdressLine1,
        sectionData.companyPostCode,
        sectionData.companyCity,
        sectionData.companyCountry,
        sectionData.companyNip,
        sectionData.companyEmail,
        sectionData.emailFlag
      );
    }
    return status;
  };

  const toogleCompanyEmail = (value: boolean) => {
    console.log("value", value);
    if (value) {
      updateSectionData({
        ...sectionData,
        companyEmail: "",
        emailFlag: true,
      });
    } else {
      updateSectionData({
        ...sectionData,
        companyEmail: sectionData.login,
        emailFlag: false,
      });
    }
  };

  return (
    <Aligment justify="center" direction="column">
      <LoginTite>{t(dictionary.registerTitle)}</LoginTite>

      {sectionData.step === 0 && (
        <RegisterEmail
          loginValue={sectionData.login}
          orgNameValue={sectionData.organizationName}
          handleChange={(name, value) => setValue(name, value as string)}
          handleSubmit={verifyEmailAddress}
          isJoinMode={isJoinMode}
        />
      )}
      {sectionData.step === 1 && (
        <RegisterVerificationCode
          inputValue={sectionData.verifyCode}
          handleChange={(name, value) => setValue(name, value as string)}
          handleSubmit={verifyCode}
        />
      )}
      {sectionData.step === 2 && (
        <RegisterPassword
          inputValue={sectionData.password}
          handleChange={(name, value) => setValue(name, value as string)}
          handleSubmit={isJoinMode ? registerUser : goToSelectPlan}
        />
      )}
      {sectionData.step === 3 && (
        <RegisterPlan
          plans={pricePlans}
          activePlan={sectionData.orgPlanID}
          handleSelect={selectUserPlan}
        />
      )}
      {sectionData.step === 4 && (
        <RegisterPaymentDetails
          companyName={sectionData.companyName}
          companyAdressLine1={sectionData.companyAdressLine1}
          companyAdressLine2={sectionData.companyAdressLine2}
          companyPostCode={sectionData.companyPostCode}
          companyCity={sectionData.companyCity}
          companyCountry={sectionData.companyCountry}
          companyNip={sectionData.companyNip}
          companyEmail={sectionData.companyEmail}
          emailFlag={sectionData.emailFlag}
          handleChange={(name, value) => setValue(name, value as string)}
          handleToogleChange={toogleCompanyEmail}
        />
      )}
      {
        <>
          {sectionData.error && (
            <LoginFormSection>
              <ErrorArea>{errorMsg(sectionData.errorCode)}</ErrorArea>
            </LoginFormSection>
          )}
          {sectionData.probe === 2 ? (
            <LoginActionLink onClick={() => verifyEmailAddress()}>
              {t(dictionary.sendCodeAgain)}
            </LoginActionLink>
          ) : (
            <Button
              text={setButtonText()}
              onClick={nextAction}
              loader={sectionData.loading}
              disabled={checkDisable()}
            />
          )}
        </>
      }
      <LoginFooterSection>
        <TextSmall>
          {t(dictionary.doYouHaveAccount)}
          <LoginActionLink onClick={() => changeMode(LOGIN_MODE.login)}>
            {t(dictionary.loginAccount)}
          </LoginActionLink>
        </TextSmall>
      </LoginFooterSection>
    </Aligment>
  );
};
