import React, { FC } from "react";
import { SettingRowWrapper } from "./Settings.styles";
import { Aligment, Icon, TextLabel } from "venice-ui";
import { Colors } from "../../untils/Theme";

interface ISettingRow {
  title: string;
  children: React.ReactNode;
  onClick?: () => void;
}

export const SettingRow: FC<ISettingRow> = ({ title, onClick, children }) => {
  return (
    <SettingRowWrapper>
      <Aligment gap={10}>
        <TextLabel>{title}</TextLabel>
        {onClick && (
          <Icon
            name="edit"
            size={16}
            noPadding
            iconBgColor="transparent"
            iconBgHoverColor="transparent"
            onClick={onClick}
          />
        )}
      </Aligment>
      {children}
    </SettingRowWrapper>
  );
};
