import { t } from "../helpers/userHelper";
import { dictionary } from "../untils/dictionary";

export interface ITableHeaderProps {
  name: string;
  valueSource: string;
  sort?: boolean;
  date?: boolean;
  scope?: any;
  isCount?: boolean;
}

export const invoiceListHeader: ITableHeaderProps[] = [
  {
    name: t(dictionary.invoiceNumber),
    valueSource: "number",
  },
  {
    name: t(dictionary.date),
    valueSource: "created",
    date: true,
  },
  {
    name: t(dictionary.price),
    valueSource: "price",
  },
];

export const jointHeaders: ITableHeaderProps[] = [
  {
    name: t(dictionary.jointNumber),
    valueSource: "name",
  },
  {
    name: t(dictionary.jointType),
    valueSource: "joint_type",
  },
  {
    name: t(dictionary.page),
    valueSource: "page",
  },
  {
    name: t(dictionary.element_type),
    valueSource: "element_type",
  },
  {
    name: t(dictionary.diameter_1),
    valueSource: "diameter_1",
  },
  {
    name: t(dictionary.thickness_1),
    valueSource: "thickness_1",
  },
  {
    name: t(dictionary.material_1),
    valueSource: "material_1",
  },
  {
    name: t(dictionary.diameter_2),
    valueSource: "diameter_2",
  },
  {
    name: t(dictionary.thickness_2),
    valueSource: "thickness_2",
  },
  {
    name: t(dictionary.material_2),
    valueSource: "material_2",
  },
];

export const pointsListHeaders: ITableHeaderProps[] = [
  {
    name: t(dictionary.jointNumber),
    valueSource: "name",
  },
  {
    name: t(dictionary.kks),
    valueSource: "kks",
  },
  {
    name: t(dictionary.page),
    valueSource: "page",
  },
  {
    name: t(dictionary.jointType),
    valueSource: "joint_type",
  },
  {
    name: t(dictionary.element_type),
    valueSource: "element_type",
  },
  {
    name: t(dictionary.diameter_1),
    valueSource: "diameter_1",
  },
  {
    name: t(dictionary.thickness_1),
    valueSource: "thickness_1",
  },
  {
    name: t(dictionary.material_1),
    valueSource: "material_1",
  },
  {
    name: t(dictionary.diameter_2),
    valueSource: "diameter_2",
  },
  {
    name: t(dictionary.thickness_2),
    valueSource: "thickness_2",
  },
  {
    name: t(dictionary.material_2),
    valueSource: "material_2",
  },
  {
    name: t(dictionary.welder),
    valueSource: "welder",
  },
  {
    name: t(dictionary.weldingDate),
    valueSource: "welding_date",
  },
  {
    name: t(dictionary.meltNumber_1),
    valueSource: "melt_number_1",
  },
  {
    name: t(dictionary.meltNumber_2),
    valueSource: "melt_number_2",
  },
  {
    name: t(dictionary.vTExaminationNumber),
    valueSource: "vt_examination_number",
  },
  {
    name: t(dictionary.vTExaminationDate),
    valueSource: "vt_examination_date",
  },
  {
    name: t(dictionary.vTExaminationResult),
    valueSource: "vt_examination_result",
  },
  {
    name: t(dictionary.rTExaminationNumber),
    valueSource: "rt_examination_number",
  },
  {
    name: t(dictionary.rTExaminationDate),
    valueSource: "rt_examination_date",
  },
  {
    name: t(dictionary.rTExaminationResult),
    valueSource: "rt_examination_result",
  },
  {
    name: t(dictionary.pTExaminationNumber),
    valueSource: "pt_examination_number",
  },
  {
    name: t(dictionary.pTExaminationDate),
    valueSource: "pt_examination_date",
  },
  {
    name: t(dictionary.pTExaminationResult),
    valueSource: "pt_examination_result",
  },
  {
    name: t(dictionary.uTExaminationNumber),
    valueSource: "ut_examination_number",
  },
  {
    name: t(dictionary.uTExaminationDate),
    valueSource: "ut_examination_date",
  },
  {
    name: t(dictionary.uTExaminationResult),
    valueSource: "ut_examination_result",
  },
  {
    name: t(dictionary.mTExaminationNumber),
    valueSource: "mt_examination_number",
  },
  {
    name: t(dictionary.mTExaminationDate),
    valueSource: "mt_examination_date",
  },
  {
    name: t(dictionary.mTExaminationResult),
    valueSource: "mt_examination_result",
  },
];

export const sectionListHeaders: ITableHeaderProps[] = [
  {
    name: t(dictionary.jointNumber),
    valueSource: "name",
  },
  {
    name: t(dictionary.page),
    valueSource: "page",
  },
  {
    name: t(dictionary.jointType),
    valueSource: "joint_type",
  },
  {
    name: t(dictionary.element_type),
    valueSource: "element_type",
  },
  {
    name: t(dictionary.diameter_1),
    valueSource: "diameter_1",
  },
  {
    name: t(dictionary.thickness_1),
    valueSource: "thickness_1",
  },
  {
    name: t(dictionary.material_1),
    valueSource: "material_1",
  },
  {
    name: t(dictionary.diameter_2),
    valueSource: "diameter_2",
  },
  {
    name: t(dictionary.thickness_2),
    valueSource: "thickness_2",
  },
  {
    name: t(dictionary.material_2),
    valueSource: "material_2",
  },
  {
    name: t(dictionary.weldingDate),
    valueSource: "weldingDate",
  },
  {
    name: t(dictionary.welder),
    valueSource: "welder",
  },
  {
    name: t(dictionary.meltNumber_1),
    valueSource: "meltNumber_1",
  },
  {
    name: t(dictionary.meltNumber_2),
    valueSource: "meltNumber_2",
  },
];
